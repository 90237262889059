import * as React from "react"
import PropTypes from "prop-types"

import { useState } from "react"
import { Form, FormGroup, FormControl, Button } from "react-bootstrap"
import { errorFindBlog } from "../../functions/errorFind"



const CamForm = ({ location }) => {

    const [errors, setErrors] = useState({})

    const [fields, setFields] = useState({
      firstName: "",
      lastName: "",
      email: "",
    })
  
    function submit(e) {
      e.preventDefault()
      const openErrors = errorFindBlog(fields)
      if (Object.keys(openErrors).length > 0) {
        setErrors(openErrors)
      } else {
        var xhr = new XMLHttpRequest()
        var url =
          "https://api.hsforms.com/submissions/v3/integration/submit/7763866/d1312327-550e-4dff-89a4-df8d58e1c6cc"
  
        var data = {
          fields: [
            {
              name: "firstname",
              value: fields.firstName,
            },
            {
              name: "lastname",
              value: fields.lastName,
            },
            {
              name: "email",
              value: fields.email,
            },
          ],
        }
  
        var final_data = JSON.stringify(data)
  
        xhr.open("POST", url)
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader("Content-Type", "application/json")
  
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4 && xhr.status === 200) {
            window.location.href = "/us/gated-asset-form-success"
          } else if (xhr.readyState === 4 && xhr.status === 400) {
            console.log(xhr.responseText) // Returns a 400 error the submission is rejected.
          } else if (xhr.readyState === 4 && xhr.status === 403) {
            console.log(xhr.responseText) // Returns a 403 error if the portal isn't allowed to post submissions.
          } else if (xhr.readyState === 4 && xhr.status === 404) {
            console.log(xhr.responseText) //Returns a 404 error if the formGuid isn't found
          }
        }
  
        // Sends the request
  
        xhr.send(final_data)
      }
    }


  return (
      <>
                <Form>
                  <h3>Avoid a Lack of Cyber Coverage in 2023</h3>
                  <FormGroup className="mb-3" controlId="formBasicEmail">
                    <FormControl
                      value={fields.email}
                      className="bg-white"
                      type="email"
                      placeholder="Email"
                      onChange={e =>
                        setFields({ ...fields, email: e.target.value })
                      }
                    />
                    <span type="invalid">{errors.email}</span>
                  </FormGroup>
                  <Form.Group className="mb-3" controlId="formFirstName">
                    <Form.Control
                      value={fields.firstName}
                      className="bg-white"
                      type="fName"
                      placeholder="First Name"
                      onChange={e =>
                        setFields({
                          ...fields,
                          firstName: e.target.value,
                        })
                      }
                    />

                    <span>{errors.firstName}</span>
                  </Form.Group>
                  <FormGroup
                    className="bg-white mb-3"
                    controlId="formLastName"
                  >
                    <FormControl
                      value={fields.lastName}
                      className="bg-white"
                      type="lName"
                      placeholder="Last Name"
                      onChange={e =>
                        setFields({ ...fields, lastName: e.target.value })
                      }
                    />
                    <span type="invalid">{errors.lastName}</span>
                  </FormGroup>

                  <FormGroup>
                    <Button
                      style={{
                        width: "100%",
                        backgroundColor: "transparent",
                      }}
                      className="nav-section--cta-btn"
                      variant="primary"
                      type="submit"
                      onClick={submit}
                    >
                      <h4>SIGN UP</h4>
                    </Button>
                    <p className="pt-5 white">
                      <em>
                        Vigilant is committed to ensuring the security and
                        protection of the personal information that we process,
                        and to provide a compliant and consistent approach to
                        data protection. If you have any questions related to
                        our privacy policies, please contact
                        legal@vigilantnow.com
                      </em>
                    </p>
                  </FormGroup>
                </Form>

      </>
  )
}

CamForm.propTypes = {
  siteTitle: PropTypes.string,
}

CamForm.defaultProps = {
  siteTitle: ``,
}

export default CamForm
